import React, { useEffect, useState } from "react";
import { Table, Button, Col, Row, Label, FormGroup, Input, ButtonGroup } from "reactstrap";
import { withRouter, useLocation } from "react-router-dom";

import { saveAs } from "file-saver";
import { RiFileExcel2Line } from "react-icons/ri";
import { GetLanguageString, NoResult } from "../../helper/Components";
import { GetCustomStyles } from "../../helper/Method";
import CourseSelectUnit from "../CourseSelectUnit";
import {
  LoadingSpinner,
  convertUTCToLocalTime,
} from "../../../constants/const";
import { requestApi } from "../../../actions/api";
import moment from "moment";
import { encryptStorage } from "../../../constants/EncryptStorage";
import { ApiCourse } from "../ApiCourse";
import { FcPrint } from "react-icons/fc";

const CourseParticipantsReport = ({ history }) => {
  const loggedInUnitId = parseInt(encryptStorage.getItem("loggedInUnitId"));
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const courseId = parseInt(location.state.courseId);
  const [excludeExUnit, setExcludeExUnit] = useState(true);
  const [participants, setParticipants] = useState([]);
  const [course, setCourse] = useState({
    startDate: null,
    address: "",
    description: "",
    endDate: null,
    courseType: {},
  });
  const [defaultCourseStatus, setDefaultCourseStatus] = useState(true);
  const [searchCourseModel, setSearchCourseModel] = useState({
    unit: { value: 0, lable: "" },
    isSubUnits: true,
    courseId: courseId,
    courseStatus: null,
    loggedInUnitId: loggedInUnitId,
  });

  useEffect(() => {
    setLoading(true);
    ApiCourse()
      .getCourseParticipantsReportAttendance(searchCourseModel)
      .then((res) => {
        setParticipants(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));

    requestApi("course/")
      .fetchById(courseId)
      .then((res) => {
        setCourse(res.data);
      });
  }, [searchCourseModel, courseId]);

  const handleSubUnits = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setSearchCourseModel({
      ...searchCourseModel,
      isSubUnits: v,
    });
  };

  const handleCourseStatus = (e) => {
    const value = e.target.value;
    let coursestatus = null;
    if (value === "0") setDefaultCourseStatus(true);
    else setDefaultCourseStatus(false);

    if (value === "1") coursestatus = true;
    else if (value === "2") coursestatus = false;
    setSearchCourseModel({
      ...searchCourseModel,
      courseStatus: coursestatus,
    });
  };

  const handleExcludeExUnit = (e) => {
    const { value } = e.target;
    let v = value;
    if (value === "true") v = true;
    else v = false;
    setExcludeExUnit(v);
  };

   const excelDownload = () => {
        const modal ={
          memberList: participants,
          model: course,
          isReport: true,
        }
        ApiCourse()
        .GenerateCourseParticipantsReport(modal)
          .then((res) => {
            const blob = new Blob([res.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, "CourseParticipantsReport.xlsx");
          })
          .catch((err) => console.log(err));
      };

  return (
    <div className="wrapper-custom wrapper wrapper-content animated fadeInRight">
      <div className="ibox ">
        <div className="ibox-title">
          <h5>{<GetLanguageString props="member_search_member" />}</h5>
          <Button
            color="primary"
            size="xs"
            className={GetCustomStyles().btn_style}
            onClick={() => history.goBack()}
          >
            {<GetLanguageString props="courses_participants_back" />}
          </Button>
        </div>
        <div className="ibox-content">
          <Label>
            {<GetLanguageString props="courses_participants_course_status" />}
          </Label>
          <Row className=" m-t-sm">
            <Col md="2">
              <FormGroup check>
                <Label check for="all">
                  <Input
                    id="all"
                    type="radio"
                    name="courseStatus"
                    checked={defaultCourseStatus}
                    value={0}
                    onClick={handleCourseStatus}
                  />
                  {<GetLanguageString props="courses_status_all" />}
                </Label>
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup check>
                <Label check for="completed">
                  <Input
                    id="completed"
                    type="radio"
                    name="courseStatus"
                    value={1}
                    onClick={handleCourseStatus}
                  />
                  {<GetLanguageString props="courses_status_completed" />}
                </Label>
              </FormGroup>
            </Col>
            <Col md="2">
              <FormGroup check>
                <Label check for="inCompleted">
                  <Input
                    id="inCompleted"
                    type="radio"
                    name="courseStatus"
                    value={2}
                    onClick={handleCourseStatus}
                  />
                  {<GetLanguageString props="courses_status_incompleted" />}
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <CourseSelectUnit
            values={searchCourseModel}
            setValues={setSearchCourseModel}
            excludeExUnit={excludeExUnit}
          />
          {searchCourseModel.unit.value !== 0 ? (
            <Row>
              <Col md="2">
                <FormGroup check>
                  <Label check for="excludeExUnit">
                    <Input
                      id="excludeExUnit"
                      type="checkbox"
                      name="excludeExUnit"
                      checked={!excludeExUnit}
                      value={!excludeExUnit}
                      onChange={handleExcludeExUnit}
                    />
                    {<GetLanguageString props="member_search_member_ex_unit" />}
                  </Label>
                </FormGroup>
              </Col>
              <Col md="2">
                <FormGroup check>
                  <Label check for="subUnit">
                    <Input
                      id="subUnit"
                      type="checkbox"
                      name="isSubUnits"
                      checked={searchCourseModel.isSubUnits}
                      value={!searchCourseModel.isSubUnits}
                      onChange={handleSubUnits}
                    />
                    {
                      <GetLanguageString props="member_search_member_sub_unit" />
                    }
                  </Label>
                </FormGroup>
              </Col>
            </Row>
          ) : null}
        </div>
      </div>

      <div className="ibox">
        <div className="ibox-title">
          <h3>
            {<GetLanguageString props="courses_participants_course_details" />}
          </h3>
        </div>
        <div className="ibox-content">
          <Row>
            <Col md="6">
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="common_name" />} :
                </h4>
                <h4 className="col-7 ur">{course.courseType.label}</h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="courses_address" />} :
                </h4>
                <h4 className="col-7 ur">
                  {course.address !== null && course.address !== ""
                    ? course.address
                    : course.courseLocation?.label}
                </h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {
                    <GetLanguageString props="course_participants_total_participants" />
                  }{" "}
                  :
                </h4>
                <h4 className="col-7">{course.totalParticipants}</h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {
                    <GetLanguageString props="courses_participants_course_status" />
                  }{" "}
                  :
                </h4>
                <h4
                  className="col-7"
                  style={{ color: course.isPublished ? "green" : "red" }}
                >
                  {course.isPublished ? "Published" : "Not Published"}
                </h4>
              </Row>
            </Col>
            <Col md="6">
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="courses_start_date" />} :
                </h4>
                <h4 className="col-7">
                  {moment(convertUTCToLocalTime(course.startDate)).format(
                    "DD/MM/yyyy"
                  )}
                </h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {<GetLanguageString props="courses_end_date" />} :
                </h4>
                <h4 className="col-7">
                  {moment(convertUTCToLocalTime(course.endDate)).format(
                    "DD/MM/yyyy"
                  )}
                </h4>
              </Row>
              <Row>
                <h4 className="col-4 text-right">
                  {
                    <GetLanguageString props="course_participants_total_completed_participants" />
                  }{" "}
                  :
                </h4>
                <h4 className="col-7">{course.totalPartcipantsCompleted}</h4>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
      <div className="ibox" id="section-to-print">
        <div className="ibox-title noprint">
          <h5>{<GetLanguageString props="courses_participants_list" />}</h5>
          <ButtonGroup className={GetCustomStyles().unit_btn_style}>
          <Button
            color="default"
            size="sm"
            onClick={() => window.print()}
          >
            <FcPrint size={15} className={GetCustomStyles().fa_style} />
            {<GetLanguageString props="common_print" />}
          </Button>
           {/* <Button
              color="default"
              size="sm"
              onClick={excelDownload}
            >
              <RiFileExcel2Line
                  size={15}
                  color="#009440"
                  style={{ marginRight: 3, marginBottom: 1 }}
                />
              <GetLanguageString props="member_report_export_excel" />
            </Button> */}
            </ButtonGroup>
        </div>
        <div className="ibox-content">
          {!loading ? (
            participants.length !== 0 ? (
              <div className="table-responsive">
                <Table className="table-stripped table-bordered footable">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{<GetLanguageString props="common_name" />}</th>
                      <th>{<GetLanguageString props="member_father_name" />}</th>
                      <th>{<GetLanguageString props="member_reg_number" />}</th>
                      <th>
                        {<GetLanguageString props="settings_member_type" />}
                      </th>
                      <th>{<GetLanguageString props="unit_unit_table" />}</th>
                      <th>
                        {
                          <GetLanguageString props="courses_participants_comments" />
                        }
                      </th>
                      <th>
                        {
                          <GetLanguageString props="courses_participants_course_status" />
                        }
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {participants?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="ur">{record.fullName}</td>
                          <td className="ur">{record.fatherName}</td>
                          <td>{record.regNo}</td>
                          <td className="ur">{record.memberType}</td>
                          <td className="ur">{record.unitName}</td>
                          <td className="ur">{record.courseComments}</td>
                          <td
                            style={{ color: record.courseStatus ? "green" : "red" }}
                          >
                            {record.courseStatus ? (
                              <GetLanguageString props="courses_status_completed" />
                            ) : (
                              <GetLanguageString props="courses_status_incompleted" />
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            ) : (
              <NoResult />
            )
          ) : (
            LoadingSpinner()
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(CourseParticipantsReport);
